<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div uk-grid>
      <div class="uk-width-2-3@s font-header">
        Ubah Profil
      </div>
      <div class="uk-width-1-3@s">
        <bread-crumb :bread-crumb="breadcrumb" />
      </div>
    </div>

    <container :data-user="user" />
    <modal-out-confirm />
    <modal-save-confirm />
  </div>
</template>

<script>
import Container from './Container'
import { dateString } from '@/utils/formater'
import ModalOutConfirm from './ModalOutConfirm'
import ModalSaveConfirm from './ModalSaveConfirm'
import { mapActions, mapGetters } from 'vuex'
import * as Cookies from 'js-cookie'
import BreadCrumb from '@/components/globals/breadcrumb'

export default {
  components: {
    Container,
    ModalOutConfirm,
    ModalSaveConfirm,
    BreadCrumb
  },
  data() {
    return {
      id: Cookies.get('paranje-id'),
      breadcrumb: [
        {
          link: '/admin/profile',
          title: 'Profil Saya'
        },
        {
          link: null,
          title: 'Ubah Profil'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    })
  },
  async mounted() {
    await this.getUserDetail(this.id)
  },
  methods: {
    ...mapActions({
      getUserDetail: 'user/getUserDetail'
    }),
    getDatetime(date) {
      return dateString(date)
    }
  }
}
</script>
