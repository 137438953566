<template>
  <div class="uk-margin-top">
    <div class="uk-card uk-card-default uk-padding-small profile-wrapper">
      <div
        class="uk-grid-small"
        uk-grid
      >
        <div
          style="padding: 0 30px 0 32px;"
          class="uk-width-1-2@l uk-width-1-1@m"
        >
          <div class="profile-content uk-first-column">
            <label
              class="input-title uk-form-label"
              for="cari"
            >User ID</label>
            <div class="uk-form-controls">
              <input
                v-model="formUser.id"
                class="label-field uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
          <div class="profile-content uk-first-column">
            <label
              class="input-title uk-form-label"
              for="cari"
            >Nama User</label>
            <div class="uk-form-controls">
              <input
                v-model="formUser.name"
                v-validate="'required|alpha_spaces|min:5|max:30'"
                class="input-field uk-input"
                name="name"
                type="text"
                :class="{'uk-form-danger': errors.has('name')}"
              >
            </div>
          </div>
          <div class="profile-content uk-first-column">
            <label
              class="input-title uk-form-label"
              for="cari"
            >Email</label>
            <div class="uk-form-controls">
              <input
                v-model="formUser.email"
                v-validate="'required|email'"
                class="input-field uk-input"
                name="email"
                type="text"
                :class="{'uk-form-danger': errors.has('email')}"
              >
            </div>
          </div>
          <div class="profile-content uk-first-column">
            <label
              class="input-title uk-form-label"
              for="cari"
            >No. Handphone</label>
            <div class="uk-form-controls">
              <input
                v-model="formUser.phone"
                v-validate="'required|numeric|min:9|max:15'"
                class="input-field uk-input"
                name="phone"
                type="text"
                :class="{'uk-form-danger': errors.has('phone')}"
              >
            </div>
          </div>
        </div>
        <div
          style="padding: 0 32px 0 30px;"
          class="uk-width-1-2@l uk-width-1-1@m"
        >
          <div class="profile-content uk-first-column">
            <label
              class="input-title uk-form-label"
              for="cari"
            >Jabatan</label>
            <div class="uk-form-controls">
              <input
                v-model="formUser.position"
                class="label-field uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
          <div class="profile-content uk-first-column">
            <label
              class="input-title uk-form-label"
              for="cari"
            >Role</label>
            <div class="uk-form-controls">
              <input
                v-model="formUser.role"
                class="label-field uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
          <div class="profile-content uk-first-column">
            <label
              class="input-title uk-form-label"
              for="cari"
            >Foto Profil</label>
            <div
              style="width: 272px; display: flex; align-items: center;"
              class="uk-form-controls"
            >
              <img
                style="margin-right: 16px; border-radius: 40px"
                :src="image_upload ? image_upload : !dataUser.photo ? `${images}/avatar.png` : image_url+formUser.file"
                class="profil-img"
              >
              <div class="js-upload upload-wrap">
                <input
                  ref="image_upload"
                  class="input-btn"
                  type="file"
                  name="image_upload"
                  accept="image/png,image/jpg,image/jpeg"
                  multiple
                  @change="onFileChange"
                >
                <button
                  class="btn-upload uk-button uk-button-default"
                  type="button"
                  tabindex="-1"
                >
                  Pilih Foto
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="uk-width-1-1 uk-text-right uk-margin-top">
        <button
          class="uk-button uk-button-danger"
          type="button"
          @click="goToBack"
        >
          <img
            v-lazy="`${images}/icon/close.svg`"
            alt=""
            class="uk-margin-small-right"
          >
          Batal
        </button>
        <button
          class="uk-button uk-button-primary uk-margin-left"
          type="button"
          @click="showSaveConfirmModal"
        >
          <img
            v-lazy="`${images}/icon/save.svg`"
            alt=""
            class="uk-margin-small-right"
          >
          Simpan
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant'
import { mapMutations } from 'vuex'
import {
  notificationDanger
} from '@/utils/notification'

export default {
  props: {
    dataUser: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      image_url: process.env.VUE_APP_IMAGE_URL,
      image_upload: null,
      formUser: {
      }
    }
  },
  watch: {
    async dataUser() {
      this.formUser = {
        id: this.dataUser.id,
        name: this.dataUser.name,
        email: this.dataUser.email,
        phone: this.dataUser.phone,
        position: this.dataUser.position,
        role: this.dataUser.role,
        file: this.dataUser.photo
      }
    }
  },
  methods: {
    onFileChange(e) {
      const files = e.target.files[0]
      const fileSize = files.size / 1024 / 1024
      if (fileSize > 1) {
        e.preventDefault()
        notificationDanger({message: 'File tidak boleh lebih dari 1MB'})
      } else {
        this.formUser.file = URL.createObjectURL(files)
        this.image_upload = URL.createObjectURL(files)
      }
    },
    ...mapMutations({
      setModalProfil: 'user/SET_MODAL_PROFIL'
    }),
    goToBack() {
      window.UIkit.modal('#modal-out-confirm').show()
    },
    showSaveConfirmModal() {
      this.$validator.validateAll().then((success) => {
        if (success) {
          const formData = new FormData()
          this.$refs.image_upload.files[0] && formData.append('file', this.$refs.image_upload.files[0])
          formData.append('id', this.formUser.id)
          formData.append('name', this.formUser.name)
          formData.append('email', this.formUser.email)
          formData.append('phone', this.formUser.phone)
          formData.append('position', this.formUser.position)
          formData.append('role', this.formUser.role)

          window.UIkit.modal('#modal-save-confirm').show()
          this.setModalProfil(formData)
        }
      })
    }
  }
}
</script>

<style scoped>
.profile-wrapper {
  border-radius: 10px;
  box-shadow: 0 2px 20px #00000014;
  padding: 30px;
}
.profile-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
}
.input-title {
  font-family: ClanOT;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #025231;
}
.input-field {
  width: 340px;
  max-width: 340px;
  height: 40px;
  max-height: 40px;
  background: #ffffff;
  border-radius: 6px;
  color: #1F2E28;
}
.label-field {
  width: 340px;
  max-width: 340px;
  height: 40px;
  max-height: 40px;
  background: #F0F5F3;
  border-radius: 6px;
  color: #1F2E28;
}
.profil-img {
  width: 80px;
  max-width: 80px;
  height: 80px;
  max-height: 80px;
}
.btn-upload {
  padding: 0;
  width: 96px;
  height: 32px;
  background: #FFFFFF;
  border: 1px solid #025231;
  font-weight: bold;
}
.upload-wrap {
  overflow: hidden;
  display: inline-block;
  position: relative;
  max-width: 100%;
}
.input-btn {
  opacity: 0;
  position: absolute;
  cursor: pointer;
  height: 32px;
  margin-left: -100%;
}
.edit-btn {
  width: 124px;
  height: 40px;
  padding: 0;
}
.edit-icon {
  margin-right: 8px;
}
</style>
