import { render, staticRenderFns } from "./Container.vue?vue&type=template&id=8ca64860&scoped=true"
import script from "./Container.vue?vue&type=script&lang=js"
export * from "./Container.vue?vue&type=script&lang=js"
import style0 from "./Container.vue?vue&type=style&index=0&id=8ca64860&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ca64860",
  null
  
)

export default component.exports